<template>
    <validation-observer tag="div" ref="observer">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <div>
            <b-card class="mt-3" :header="$t('common.group.groups')">
                <b-card-text>
                    <b-row>
                        <b-col sm="3">
                            <b-form-group :label="$t(labelPrefix+'tags')">
                                <multiselect v-model="form.tags"
                                             name="tags"
                                             :searchable="true"
                                             :multiple="true"
                                             :taggable="true"
                                             @tag="addTag"
                                             :options="form.available_tags"
                                             select-label=""
                                ></multiselect>
                            </b-form-group>
                        </b-col>

                        <b-col sm="3">
                            <zw-checkbox-group name="groups"
                                               :label-prefix="labelPrefix"
                                               v-model="form.groups"
                                               text-field="name"
                                               value-field="id"
                                               :options="getGroups()"
                            ></zw-checkbox-group>
                        </b-col>

                        <b-col cols="3">
                            <zw-select-group
                                v-model="form.customer_status"
                                :options="customerStatuses"
                                name="customer_status"
                                :label-prefix="labelPrefix"
                            ></zw-select-group>
                        </b-col>

                        <b-col cols="3">
                            <zw-select-group v-model="form.care_user"
                                             :options="getUsersList()"
                                             name="care_user"
                                             :label-prefix="labelPrefix"
                                             text-field="fullname"
                                             value-field="id"
                            ></zw-select-group>
                        </b-col>
                    </b-row>
                </b-card-text>
            </b-card>

            <b-row>
                <b-col sm="6"></b-col>
                <b-col sm="3">
                    <b-button block @click="onSubmit(false,false)" variant="primary">
                        {{ $t('common.button.save') }}
                    </b-button>
                </b-col>
                <b-col sm="3">
                    <b-button block @click="onSubmit(false,true)" variant="info">
                        {{ $t('common.button.save_and_stay') }}
                    </b-button>
                </b-col>
            </b-row>
        </div>
    </validation-observer>
</template>

<script>
import {mapGetters} from "vuex";
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import ValidateMixin from "@/mixins/validate";

export default {
    name: 'customer-groups-form',
    mixins: [ValidateMixin, commonSave],
    props: {
        customerId: {
            type: Number,
        },
        sidebar: {}
    },
    data() {
        return {
            loading: true,
            labelPrefix: 'customer.label.',
            form:{
                available_tags: [],
            },
            defaultForm: {
                ignore_server_validation: true,
            },
        }
    },
    mounted() {
        this.shown()
    },
    methods: {
        ...mapGetters('Customer', ['getCustomer']),
        ...mapGetters('CommonData', ['getGroups', 'getEntityStatuses']),
        ...mapGetters('Users', ['getUsersList']),
        shown() {
            const usersList = this.$store.dispatch('Users/fetchUsersList')
            this.$store.dispatch('Customer/fetchCustomer', this.customerId)
                .then(() => {
                    this.form = JSON.parse(JSON.stringify({...this.defaultForm, ...this.getCustomer()}))

                    if (typeof this.form.available_tags === 'object') {
                        this.form.available_tags = Object.values(this.form.available_tags)
                    }

                    this.$refs['observer'].reset()
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        addTag(newTag) {
            this.form.available_tags.push(newTag)
            this.form.tags.push(newTag)
        },
        onSubmit(openOffering = false, stay = false) {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    let formData = JSON.parse(JSON.stringify(this.form))

                    this.$store.dispatch('Customer/saveCustomer', formData)
                        .then((response) => {
                            if (!stay) {
                                this.commonAfterSave(response, this.sidebar)
                            } else {
                                this.$root.$children[0].updateFormValues('customer-modal')
                                this.shown()
                            }
                        })
                        .catch(errors => {
                            if (errors) {
                                this.loading = false
                                this.$refs['observer'].setErrors(errors)
                            }
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    },
    computed: {
        customerStatuses() {
            const customerStatuses = this.getEntityStatuses()['customer'] || []
            let list = {}
            customerStatuses.map(val => {
                list[val.id] = val.name
            })

            return list;
        }
    },
}
</script>